.viewer-style-container {
    display: flex;
    width: 330px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.fg-color-white {
    color: white;
}

@media screen and (max-width: 320px ) {
    .viewer-style-container {
        display: flex;
        width: 280px;
        margin: 0 auto;
    }   
}

.ideation-series-info-section {
    background: #fff;
    width: 100%;
    border-radius: 20px;
    display: inline-block;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .ideation-series-info-item {
    background: #fff;
    width: 100%;
    padding: 3px;
    border-radius: 10px;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;    
  }
  