.ideation-series-ideation-list-container {
  height: 600px;
  width: 100%;
  background-color: azure;
  padding: 3%;
  border: solid 5px #eee;
  border-radius: 10px;
  overflow: auto;
}

/* Hide the scrollbar when the ideation content is exdended */
.ideation-series-ideation-list-container::-webkit-scrollbar {
  display: none;
}

.ideation-series-ideation-item {
  float: left;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 10px;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
  transition: 0.3s;
  margin-bottom: 10px;
}

.ideation-series-ideation-info-section {
  height: 30px;
  float: left;
  width: 100%;
}

.ideation-series-ideation-info-section h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
