.ideation_view_container {
    padding-top: 50px;
}

.ideation_carousel {
    padding-bottom: 50px;
}

.ideation_carousel h4 {
    color:white;
}

.ideation_discover_container {
    padding-top: 50px;
    width: 100%;;
}


header.masthead {
    position: relative;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
  
  }
  
  header.masthead hr {
    background-color: white;
  }
  header.masthead .header-content {
    max-width: 500px;
    margin-bottom: 100px;
    text-align: center;
    color:white;
  }
  
  header.masthead .header-content h1 {
    font-size: 30px;
  }
  
  header.masthead .device-container {
    max-width: 325px;
    margin-right: auto;
    margin-left: auto;
  }
  
  header.masthead .device-container .screen img {
    border-radius: 3px;
  }
  
  @media (min-width: 992px) {
    header.masthead {
      height: 100vh;
      min-height: 775px;
      padding-top: 0;
      padding-bottom: 0;
    }
    header.masthead .header-content {
      margin-bottom: 0;
      text-align: left;
    }
    header.masthead .header-content h1 {
      font-size: 50px;
    }
    header.masthead .device-container {
      max-width: 325px;
    }
  }
  
  section {
    padding: 100px 0;
  }
  
  section h2 {
    font-size: 50px;
  }
  
  
  