.ideation-item {
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.ideation-item p {
    padding: 0;
    margin:0;
}

.ideation-subject-para {
    font-size: 13px;
    font-style: italic;
}

.suspended {
    color: red;
}

.new {
    color: orange;
}

.published {
    color: green;
}