.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(250, 219, 219, 0.698);
    z-index: 1000;
    text-align: center;
}

.loader {
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 10000;
    position: absolute;
}