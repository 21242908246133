.ideation_viewport {
  position: relative;
  height: 600px;
  float: left;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  border: solid 8px #ccc;
  overflow: hidden;
}

.ideation-margin-bottom-10px {
  margin-bottom: 10px;
}

.ideation-margin-top-10px {
  margin-top: 10px;
}

.ideation_bottom_controls {
  width: 100%;
  position: absolute;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0px;
  background: #a5b9ce;
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ideation_topic {
  padding-top: 5px;
  overflow: hidden;
  color: #7b4397;
}
.ideation-field_text {
  /* font-size: 12px; */
  overflow: hidden;
  font-style: italic;
}

.ideation-warning-para {
  font-size: 12px;
  color: orange;
}

.ideation_content {
  height: 480px;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Hide the scrollbar when the ideation content is exdended */
.ideation_content::-webkit-scrollbar {
  display: none;
}

.ideation_content pre {
  overflow-x: hidden;
}

.ideation-a-href {
  color: white;
}

.ideation-a-href:hover {
  color: white;
  font-weight: bold;
}
