/* sign in FORM */
.center-align {
  text-align: center;
}

#logreg-forms {
  width: 412px;
  margin: 10vh auto;
  background-color: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: solid 10px #7b4397;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

#logreg-forms .header {
  width: 100%;
  padding-top: 20px;
  background-color: #fcfcfc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 10px;
}

#logreg-forms form {
  width: 100%;
  max-width: 410px;
  padding: 15px;
  margin: auto;
}
#logreg-forms .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
#logreg-forms .form-control:focus {
  z-index: 2;
}
#logreg-forms .form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#logreg-forms .form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#logreg-forms .social-btn {
  color: white;
}

#logreg-forms a {
  display: block;
  padding-top: 10px;
  color: lightseagreen;
}

#logreg-form .lines {
  width: 200px;
  border: 1px solid red;
}

#logreg-forms button[type="submit"] {
  margin-top: 10px;
}

#logreg-forms .facebook-btn {
  background-color: #3c589c;
}

#logreg-forms .google-btn {
  background-color: #df4b3b;
}

#logreg-forms .form-signup input {
  margin-bottom: 2px;
}

.form-signup .social-login {
  /* width:210px !important; */
  /* margin: 0 auto; */
  color: #fff;
}

.dissolved-textfield-top {
  margin-bottom: -1px !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dissolved-textfield-middle {
  margin-bottom: -1px !important;
  border-radius: 0px;
}

.dissolved-textfield-bottom {
  margin-bottom: -1px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/* Mobile */

@media screen and (max-width: 500px) {
  #logreg-forms {
    width: 100%;
    margin: 4vh auto;
  }

  #logreg-forms .mb-3 {
    padding:0px;
    margin: 0px;
  }
  #logreg-forms .social-login {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #logreg-forms .social-btn {
    font-size: 1.3rem;
    font-weight: 100;
    color: white;
    width: 200px;
    height: 56px;
  }
  #logreg-forms .social-btn:nth-child(1) {
    margin-bottom: 5px;
  }
  #logreg-forms .social-btn span {
    display: none;
  }
  #logreg-forms .facebook-btn:after {
    content: "Facebook";
  }

  #logreg-forms .google-btn:after {
    content: "Google+";
  }
}

#logreg-forms .accept-checkbox {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

#logreg-forms .accept-checkbox a {
  display: inline;
}
