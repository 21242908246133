.carousel_dark_indicators .carousel-indicators li {
    background-color:#aaa;
}

.carousel_dark_indicators .carousel-indicators .active {
    background-color: #000 !important;
}

.carousel_light_indicators ol li {
    background-color:#fff;
}

.carousel_light_indicators .carousel-indicators .active {
    background-color: #ccc !important;
}

