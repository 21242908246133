.mi-details-container{    
    box-shadow: 0 4px 8px 0 #00000033;
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.mi-details-container .card {
    box-shadow: 0 2px 8px 0 #00000033;
    border-radius: 6px;    
}

.mi-details-container .alert {
    margin-top: 20px;
}

.mi-details-container .feature_section {
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 0px 0px 0 #00000033;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .mi-details-container .card {
        margin-bottom: 20px;
    }
}