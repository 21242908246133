.portal_outer_container {
  margin-top: 50px;
}

.section_container {
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.top_nav_section {
  min-height: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.circle_btn {
  margin-top: 10px;
  height: 60px;
  line-height: 60px;
  width: 60px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #692186;
  color: white;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.circle_btn:hover,
.circle_btn_hover {
  background-color: #8f07c8;
  color: white;
}

.ideation_form_icon {
  color: #eee;
  padding-top: 5px;
  font-size: 32px;
}

.ideation_form_icon:hover {
  color: white;
}

.ideation_list_form_icon {
  font-size: 30px;
  color: #525252;
}

.ideation_list_form_icon:hover {
  color: #692186;
}

.navbar_metric {
  border-right: solid 1px #ccc;
}

.navbar_metric_icon {
  float: left;
  color: #d82534;
  font-size: 16px;
  width: 100%;
  height: 20px;
  text-align: center;
}

.navbar_metric_info {
  float: left;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.navbar_metric_label {
  float: left;
  width: 100%;
  height: 25px;
  font-size: 14px;
  padding-top: 5px;
  text-align: center;
}

.content_section {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  border-radius: 10px;
}

.content_header {
  min-height: 60px;
  background-color: #692186;
  color: #fff;
  border-radius: 9px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.content_header_heading {
  float: left;
  width: 40%;
  padding-top: 16px;
  padding-left: 20px;
}

.content_header_controls {
  width: 60%;
  padding-top: 11px;
  padding-right: 20px;
  float: right;
  text-align: right;
}

.content_section_form {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content_form_container {
  border-right: dashed 2px #ccc;
}

.content_section_form .form-control {
  margin-bottom: 10px;
}

.ideation_editor_container {
  float: left;
  height: 300px;
}
.ideation_editor_class {
  background-color: #000;
  height: 100%;
}

.unsaved_container {
  border-style: dashed; 
  border-radius: 20px;
  border-color: #ccc; 
  margin:15px;
  padding:20px;
}

/* .public-DraftStyleDefault-ltr {
    height: 300px;
    width: 100%;
    background-color: #fff;
    overflow-y: auto;
    padding:5px;
    border-radius: 5px;
    border:solid 1px #ccc;    
}

.public-DraftStyleDefault-block {
    margin: 0em !important;
}

div.DraftEditor-root {
    margin-bottom: 10px;
} */

div.DraftEditor-root {
  border: 1px solid #ccc;
  background-color: #fff;
  height: 340px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
  border-radius: 5px;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.rdw-suggestion-dropdown {
  min-width: 250px !important;
  max-height: 200px !important;
}

.rdw-suggestion-option-active {
  background-color: #692186 !important;
  color: white !important;
}
