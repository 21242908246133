.content-container {
    background-color:#fff;
    border-radius:5px;
    border: solid 1px #ccc;
    margin-bottom:20px;
}
.content-section  {
    display: flex;
    margin: 20px 0px;
    text-align: justify;
    width:100%;
}

.content-section-border {
    border-bottom:solid 1px #aaa;
}

.content-image {
    margin-top:10px;
}
.content-text {
    text-align:left;
}

#external-comm-section a {
    display: inline !important;
}

@media screen and (max-width: 500px) {
    .content-container {
        display: none;
    }
}